<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0 font" v-if="permissionCheck.viewAny == false">
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0" v-else>
  

        <banner title="ticket" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'ticket',
            }
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card">
                  <div class="row" style="padding: 10px 10px 5px 10px">
                    <div class="col-8">
                      <ul
                        class="nav nav-tabs"
                        id="myTab"
                        role="tablist"
                        style="padding: 10px 10px 10px 10px"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active btn-sm"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            @click="showBtn('inactive')"
                          >
                            <strong>All Tickets</strong>
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link btn-sm"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            @click="showBtn('active')"
                          >
                            <strong> staff Ticket</strong>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="col-4 text-end pt-2">
                      <button
                        @click="removeEror()"
                        v-if="showBtns == 'active'"
                        class="btn btn-sm text-white"
                        style="background-color: #f21000"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        + New
                      </button>
                      &nbsp;
                      <button
                        v-if="showBtns != 'active'"
                        data-bs-toggle="modal"
                        data-bs-target="#new-task-modal"
                        title="Filter"
                        class="btn btn-sm text-white"
                        style="background-color: #00364f"
                      >
                        <strong> <font-awesome-icon icon="filter" /></strong>
                      </button>
                    </div>
                  </div>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <!-- <div class="row" style="padding: 10px 10px 5px 10px">
                        <div class="col-10"></div>
                        <div class="col-2 text-end">
                          <button
                            class="btn btn-sm text-white"
                            style="background-color: #f21000"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            + New
                          </button>
                        
                        </div>
                      </div> -->
                      <div class="row" style="margin-top:5px">
                        <div
                          class="col-md-12 col-xl-12 col-sm-12"
                          style="padding: 0% 1% 0% 1%"
                        >
                          <div class="container-fluid table-scroll">
                            <table class="table table-hover table-sm">
                              <tr
                                class="text tr-head table-wrapper-scroll-y rounded-circle"
                                style="background-color: white"
                              >
                                <th
                                  class="text-truncate"
                                  style="
                                    background-color: #cdcdcd;
                                    border-radius: 8px 0px 0px 8px;
                                  "
                                >
                                  Ticket ID
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Date
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  User Name
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Ticket Subject
                                </th>

                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Department
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Project ID
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Last update
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Status
                                </th>
                                <!-- <th class="text-truncate" style="background-color: #cdcdcd">
                              Payment
                            </th> -->
                                <!-- <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th> -->
                                <th
                                  style="
                                    background-color: #cdcdcd;
                                    border-radius: 0px 8px 8px 0px;
                                  "
                                  class="text-truncate text-end"
                                >
                                  Action
                                </th>
                              </tr>
                              <tbody class="text">
                                <tr v-for="(tiket, index) in tickets" :key="index">
                                  <td>{{ tiket.ticketNo }}</td>
                                  <td class="text-truncate">
                                    {{ dateTimeWithTime(tiket.created_at) }}
                                  </td>

                                  <td class="text-truncate" v-if="tiket">
                                    <span v-if="tiket.relation">
                                      {{ tiket.relation.name }}
                                      <small
                                        >( {{ tiket.relation_type }} )
                                        <div>{{ tiket.relation.code }}</div></small
                                      ></span
                                    >
                                  </td>
                                  <!-- <td><span v-if="tiket.relation">{{ tiket.relation.name }}</span></td> -->
                                  <td class="text-truncate">{{ tiket.subject }}</td>
                                  <td class="text-truncate">
                                    <span v-if="tiket.department">{{
                                      tiket.department.name
                                    }}</span>
                                  </td>
                                  <td>
                                    <span v-if="tiket.client_service">{{
                                      tiket.client_service.clientServiceNo
                                    }}</span>
                                  </td>
                                  <td class="text-truncate">
                                    {{ dateTimeWithTime(tiket.updated_at) }}
                                  </td>
                                  <td>
                                    <p
                                      v-if="tiket.status == 'open'"
                                      class="btn btn-sm"
                                      style="
                                        width: 60px;
                                        background-color: green;
                                        color: white;
                                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                        border-radius: 50px;
                                        font-size: 10px;
                                        padding: 3px 3px 3px 3px;
                                        margin-top: 10px;
                                      "
                                    >
                                      <b>{{ tiket.status }}</b>
                                    </p>
                                    <p
                                      v-else
                                      class="btn btn-sm"
                                      style="
                                        width: 60px;
                                        background-color: #f21000;
                                        color: white;
                                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                        border-radius: 50px;
                                        font-size: 10px;
                                        padding: 3px 3px 3px 3px;
                                        margin-top: 10px;
                                      "
                                    >
                                      <b>{{ tiket.status }}</b>
                                    </p>
                                  </td>
                                  <td class="text-end">
                                    <div class="btn-group btn-group-sm" role="group">
                                     
                                      <router-link v-if="tiket.status == 'open'"
                                        to="#"
                                        @click="closeTicket(tiket)"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: #fc9003;
                                        "
                                      >
                                        <i
                                          class="fa fa-times-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </router-link>
                                      <router-link
                                        :to="`/staff/ticket-reply/${tiket.id}`"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: #00364f;
                                        "
                                      >
                                        <font-awesome-icon icon="eye" />
                                      </router-link>
                                      <!-- <router-link
                                        @click="ticketAssignValue(tiket)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        to="#"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: green;
                                        "
                                      >
                                        <font-awesome-icon icon="edit" />
                                      </router-link> -->

                                      <!-- <router-link
                                        to="#"
                                        @click="deletePackage(tiket.id)"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: red;
                                        "
                                      >
                                        <font-awesome-icon icon="trash" />
                                      </router-link> -->
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- staff ticket -->
                    <div
                      class="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div class="row" style="margin-top:5px">
                        <div
                          class="col-md-12 col-xl-12 col-sm-12"
                          style="padding: 0% 1% 0% 1%"
                        >
                          <div class="container-fluid table-scroll">
                            <table class="table table-hover table-sm">
                              <tr
                                class="text tr-head table-wrapper-scroll-y rounded-circle"
                                style="background-color: white"
                              >
                                <th
                                  class="text-truncate"
                                  style="
                                    background-color: #cdcdcd;
                                    border-radius: 8px 0px 0px 8px;
                                  "
                                >
                                  Ticket ID
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Date
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Name
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Ticket Subject
                                </th>

                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Department
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Project ID
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Last update
                                </th>
                                <th
                                  class="text-truncate"
                                  style="background-color: #cdcdcd"
                                >
                                  Status
                                </th>
                                <!-- <th class="text-truncate" style="background-color: #cdcdcd">
                              Payment
                            </th> -->
                                <!-- <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th> -->
                                <th
                                  style="
                                    background-color: #cdcdcd;
                                    border-radius: 0px 8px 8px 0px;
                                  "
                                  class="text-truncate text-end"
                                >
                                  Action
                                </th>
                              </tr>
                              <tbody class="text">
                                <tr
                                  v-for="(tiket1, index) in ticketstaffs"
                                  :key="index"
                                >
                                  <td>{{ tiket1.ticketNo }}</td>
                                  <td class="text-truncate">
                                    {{ dateTimeWithTime(tiket1.created_at) }}
                                  </td>
                                  <td class="text-truncate" v-if="tiket1">
                                    <span v-if="tiket1.relation">
                                      {{ tiket1.relation.name }}
                                      <small
                                        >( {{ tiket1.relation_type }} )
                                        <div>{{ tiket1.relation.code }}</div></small
                                      ></span
                                    >
                                  </td>

                                  <td>{{ tiket1.subject }}</td>
                                  <td class="text-truncate">
                                    <span v-if="tiket1.department">{{
                                      tiket1.department.name
                                    }}</span>
                                  </td>
                                  <td>
                                    <span v-if="tiket1.client_service">{{
                                      tiket1.client_service.clientServiceNo
                                    }}</span>
                                  </td>
                                  <td class="text-truncate">{{ dateTimeWithTime(tiket1.updated_at) }}</td>
                                  <td>
                                    <p
                                      v-if="tiket1.status == 'open'"
                                      class="btn btn-sm"
                                      style="
                                        width: 60px;
                                        background-color: green;
                                        color: white;
                                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                        border-radius: 50px;
                                        font-size: 10px;
                                        padding: 3px 3px 3px 3px;
                                        margin-top: 10px;
                                      "
                                    >
                                      <b>{{ tiket1.status }}</b>
                                    </p>
                                    <p
                                      v-else
                                      class="btn btn-sm"
                                      style="
                                        width: 60px;
                                        background-color: #f21000;
                                        color: white;
                                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                        border-radius: 50px;
                                        font-size: 10px;
                                        padding: 3px 3px 3px 3px;
                                        margin-top: 10px;
                                      "
                                    >
                                      <b>{{ tiket1.status }}</b>
                                    </p>
                                  </td>
                                  <td class="text-end">
                                    <div class="btn-group btn-group-sm" role="group">
                                      <router-link
                                      v-if="tiket1.status == 'open'"
                                        to="#"
                                        @click="closeTicket(tiket1)"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: #fc9003;
                                        "
                                      >
                                        <i
                                          class="fa fa-times-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </router-link>
                                      <router-link
                                        :to="`/staff/ticket-reply/${tiket1.id}`"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: #00364f;
                                        "
                                      >
                                        <font-awesome-icon icon="eye" />
                                      </router-link>
                                      <!-- <router-link
                                      v-if="tiket1.status == 'open'"
                                        @click="attechmetsSandticketAssignValue(tiket1)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#atachmentModal"
                                        to="#"
                                        class="btn btns btn-sm "
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: rgb(0, 60, 255);
                                          color:white;
                                        "
                                      >
                                      <i class="fa fa-upload" aria-hidden="true"></i>
                                      </router-link> -->
                                      <router-link
                                      v-if="tiket1.status == 'open'"
                                        @click="ticketAssignValue(tiket1)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        to="#"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: green;
                                        "
                                      >
                                        <font-awesome-icon icon="edit" />
                                      </router-link>

                                      <!-- <router-link
                                        to="#"
                                        @click="deletePackage(tiket.id)"
                                        class="btn btns btn-sm text-white"
                                        style="
                                          padding: 5px 4.5px 5px 4.5px;
                                          background-color: red;
                                        "
                                      >
                                        <font-awesome-icon icon="trash" />
                                      </router-link> -->
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer" v-if="staffTicketVal == 'false'">
                    <div class="row">
                      <div class="col-7">
                        <ul class="pagination">
                          <li class="page-item" @click="loadTicket(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadTicket(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 3"
                            @click="loadTicket(pagination.current_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 2"
                            @click="loadTicket(pagination.current_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page > 1"
                            @click="loadTicket(pagination.current_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li
                            class="active page-item"
                            @click="loadTicket(pagination.current_page)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 4"
                            @click="loadTicket(pagination.current_page + 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 5"
                            @click="loadTicket(pagination.current_page + 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 6"
                            @click="loadTicket(pagination.current_page + 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadTicket( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadTicket( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 3"
                            @click="loadTicket(pagination.last_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 2"
                            @click="loadTicket(pagination.last_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination.current_page < pagination.last_page - 1"
                            @click="loadTicket(pagination.last_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination.last_page - 1
                            }}</a>
                          </li>

                          <li class="page-item" @click="loadTicket(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadTicket(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-4 text-end">
                        Showing {{ pagination.from }} to {{ pagination.to }} of
                        {{ pagination.total }} entries
                      </div>
                      <div
                        class="col-1 text-end"
                        style="height: 30px; padding: 5px 5px 5px 5px"
                      >
                        <select
                          style="height: 30px; padding: 5px 5px 5px 5px"
                          class="form-select"
                          @change="changePageNo"
                          aria-label="Default select example"
                        >
                          <!-- <option value="5">5</option> -->
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer" v-else>
                    <div class="row">
                      <div class="col-7">
                        <ul class="pagination">
                          <li class="page-item" @click="loadTicket(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadTicket(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page > 3"
                            @click="loadTicket(pagination1.current_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page > 2"
                            @click="loadTicket(pagination1.current_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page > 1"
                            @click="loadTicket(pagination1.current_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page - 1
                            }}</a>
                          </li>
                          <li
                            class="active page-item"
                            @click="loadTicket(pagination1.current_page)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 4"
                            @click="loadTicket(pagination1.current_page + 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page + 1
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 5"
                            @click="loadTicket(pagination1.current_page + 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page + 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 6"
                            @click="loadTicket(pagination1.current_page + 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination1.current_page < (pagination1.last_page - 4)" @click="loadTicket( pagination1.current_page + 4 )"><a href="#">{{pagination1.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination1.current_page < (pagination1.last_page - 4)" @click="loadTicket( pagination1.last_page - 4 )"><a href="#">{{pagination1.last_page - 4}}</a></li> -->
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 3"
                            @click="loadTicket(pagination1.last_page - 3)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.last_page - 3
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 2"
                            @click="loadTicket(pagination1.last_page - 2)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.last_page - 2
                            }}</a>
                          </li>
                          <li
                            class="page-item"
                            v-if="pagination1.current_page < pagination1.last_page - 1"
                            @click="loadTicket(pagination1.last_page - 1)"
                          >
                            <a class="page-link" href="#">{{
                              pagination1.last_page - 1
                            }}</a>
                          </li>

                          <li class="page-item" @click="loadTicket(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadTicket(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-4 text-end">
                        Showing {{ pagination1.from }} to {{ pagination1.to }} of
                        {{ pagination1.total }} entries
                      </div>
                      <div
                        class="col-1 text-end"
                        style="height: 30px; padding: 5px 5px 5px 5px"
                      >
                        <select
                          style="height: 30px; padding: 5px 5px 5px 5px"
                          class="form-select"
                          @change="changePageNo"
                          aria-label="Default select example"
                        >
                          <!-- <option value="5">5</option> -->
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>


  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">Ticket</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" v-if="msgImgError1">
              <div class="alert alert-danger p-1" role="alert">
                <small>{{ msgImgError1 }}</small>
              </div>
            </div>
          </div>
          <form @submit.prevent="edit == 'false' ? createTicket() : updateTicket()">
            <div class="row">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label> Select Department <small style="color: #f21000">*</small></label>

                <select
                  required
                  v-model="form.role_id"
                  class="form-select"
                  aria-label="Default select example"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                >
                  <option
                    v-for="(depart, index) in departMents"
                    :key="index"
                    :value="depart.id"
                  >
                    {{ depart.name }}
                  </option>
                </select>
              </div>

              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label> Project ID </label>

                <input
                v-model="projectId"
                  type="text"
                  class="form-control"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  placeholder="Project ID"
                  @change="findClientServiceId"
                />
                <span v-if="msgErrorProjectId" class="text-danger">
                  {{ msgErrorProjectId }}
                </span>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label> Ticket Subject <small style="color: #f21000">*</small></label>
                <input
                  required
                  v-model="form.subject"
                  type="text"
                  class="form-control"
                  name=""
                  id=""
                  placeholder="Ticket Subject"
                  style="height: 30px"
                />

                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.subject">{{
                      form.errors.error.subject[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label> Message <small style="color: #f21000">*</small></label>

                <textarea
                  required
                  v-model="form.message"
                  name=""
                  id=""
                  rows="3"
                  class="form-control"
                  placeholder="Message"
                ></textarea>

                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.message">{{
                      form.errors.error.message[0]
                    }}</span></span
                  ></small
                >
              </div>

              <!-- <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label> Attachment </label>
                <input
                  type="file"
                  @change="attachmentImageUpload"
                  class="form-control"
                  name=""
                  id=""
                  placeholder="Ticket Subject"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                />
              </div> -->
            </div>


            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


    <!-- Modal -->
    <div
    class="modal fade"
    id="atachmentModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="color: #00364f">
            <strong >Ticket Attachments</strong> (Optional)
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" v-if="msgImgError1">
              <div class="alert alert-danger p-1" role="alert">
                <small>{{ msgImgError1 }}</small>
              </div>
            </div>
          </div>
          <!-- <form @submit.prevent="edit == 'false' ? createTicket() : updateTicket()"> -->
            <div class="row">
             

              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12 "
                style="padding: 5px 5px 5px 5px"
              >
                <!-- <label> Attachment </label>
                <input
                  type="file"
                  @change="attachmentImageUpload"
                  class="form-control"
                  name=""
                  id=""
                  placeholder="Ticket Subject"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                /> -->


                <label for="formFileNew" class="form-label">
                  <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
             <div style="color:#00364f">
             <strong>
               New
             </strong>
             </div>
                    </label>
  <input  @change="newUploadAttachment($event) " class="form-control" type="file" id="formFileNew" style="position:absolute;top:-150000000000000px">

              </div>
            </div>

            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th class="text-end">Edit</th>
              </tr>
              <tbody>
                <tr v-for="(atc,index) in ticketDoccuments" :key="index">
                  <td>
                
                    <img style="width:30px" :src="ticketFinder(atc)" alt="">
                  </td>

                  <td class="text-truncate text-end">
           
  <label :for="`formFile${atc.id}`" class="form-label">  <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      /></label>
  <input  @change="uploadAttachment($event,atc) " class="form-control" type="file" :id="`formFile${atc.id}`" style="position:absolute;top:-150000000000000px">

                  </td>
                </tr>


              
              </tbody>
            </table>


            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              
            </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>



  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="removeEror()"
                    v-model="filterType"
                    value="ticketNo"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="ticketNo"
                  />
                  <label class="form-check-label" for="ticketNo"> Ticket ID </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="removeEror()"
                    v-model="filterType"
                    value="date"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="date"
                  />
                  <label class="form-check-label" for="date"> Date </label>
                </div>
              </li>

              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="removeEror()"
                    v-model="filterType"
                    value="client_service_id"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="client_service_id"
                  />
                  <label class="form-check-label" for="client_service_id">
                    Project ID
                  </label>
                </div>
              </li> -->
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="removeEror()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="all"
                  />
                  <label class="form-check-label" for="all"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input
                type="date"
                v-if="
                  filterType == 'date' ||
                  filterType == 'dueDate' ||
                  filterType == 'completionDate'
                "
                class="form-control datepicker"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'ticketNo'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <input
                type="text"
                v-if="filterType == 'client_service_id'"
                @change="findClientServiceIdForFilter"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
            </div>

            <button
              v-if="filterType != 'client_service_id'"
              @click="loadTicket()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SatffTicket",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      ticket_id: '',
      projectId : '',
      staffTicketVal: "false",
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      pagination1: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      showBtns: "",
      departMents: [],
      loading: false,
      clients: [],
      tickets: [],
      ticketstaffs: [],
      ticketDoccuments: [],
      catchStatus: "",
      client_service_id: null,
      stsCheck: "",
      edit: "false",
      form: {
        message: "",
        attachment: "",
        service_id: "",
        client_id: "",
        remark: "",
        role_id: 4,
        subject: "",
        client_service_id: null,
        errors: {},
      },
      permissionCheck: {
        view: "",
        viewAny: true,
        kycView: "",
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
      msgImgError1: "",
      msgErrorProjectId: "",
      //   form: new Form({
      //     client_service_id: null,
      //     remark: "",
      //     errors: {},
      //   }),
    };
  },
  methods: {
    attechmetsSandticketAssignValue(ticket){
    console.log(ticket)
    console.log(ticket)


this.ticket_id = ticket.id
    this.loadTicketDoccuments()

    },

    newUploadAttachment(event){

      var form = {}

     
        form.ticket_id = this.ticket_id
        form.relation_id = this.ticket_id
        form.relation_type = 'ticket'
        // form.ticket_detail_id = ''
    
          

var atcExtension

         var msgImgError = "";
      var fileName;
      fileName = document.querySelector("#formFileNew").value;
      atcExtension = fileName.split(".").pop();
      // alert(atcExtension)

      if (event.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           atcExtension == "png" ||
          atcExtension == "jpeg" ||
          atcExtension == "jpg" ||
          atcExtension == "pdf" ||
          atcExtension == "PNG" ||
          atcExtension == "JPEG" ||
          atcExtension == "JPG" ||
          atcExtension == "PDF"
        ) {
          // this.panImg = URL.createObjectURL(event.target.files["0"]);
          form.attachment = event.target.files["0"];
          this.saveNewTicketDoccument(form)
          // if (atcExtension == "pdf") {
          //   this.panImg = "/assets/image/pdf.png";
          // }
        } else {
          msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" +msgImgError + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
        }
      } else {
        msgImgError = "File Size Too Big 2MB";

        this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" +msgImgError + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
      }
    },

    uploadAttachment(event,attachment){

      var form = {}

      form = attachment
        // form.ticket_id = this.ticket_id
        // form.relation_id = this.ticket_id
        // form.relation_type = 'ticket'
   
        // console.log('=====================0===================')
        // console.log(attachment)
        // console.log(form)
        // console.log('========================================')

       

var atcExtension

         var msgImgError = "";
      var fileName;
      fileName = document.querySelector(`#formFile${attachment.id}`).value;
      atcExtension = fileName.split(".").pop();

      if (event.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           atcExtension == "png" ||
          atcExtension == "jpeg" ||
          atcExtension == "jpg" ||
          atcExtension == "pdf" ||
          atcExtension == "PNG" ||
          atcExtension == "JPEG" ||
          atcExtension == "JPG" ||
          atcExtension == "PDF"
        ) {
          // this.panImg = URL.createObjectURL(event.target.files["0"]);
          form.attachment = event.target.files["0"];
          this.editTicketDoccument(form)
          // if (atcExtension == "pdf") {
          //   this.panImg = "/assets/image/pdf.png";
          // }
        } else {
          msgImgError = "File type must JPG ,JPEG, PNG , PDF ";
          this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" +msgImgError + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
        }
      } else {
        msgImgError = "File Size Too Big 2MB";

        this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" +msgImgError + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
      }
    },

   


    saveNewTicketDoccument(form) {
      const formData = new FormData();
        const keys = Object.keys(form);
        for (let n = 0; n < keys.length; n += 1) {
          if (form[keys[n]] !== null) {
            formData.append(keys[n], form[keys[n]]);
          }
        }
        // formData.append("staff_id", this.staff_id);
     
        this.loading = true;
        this.$axios
          .post(`staff/clientserviceticket/document`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            document.querySelector("#formFileNew").value = ''
            toast.success("Updated Succsessfully", {
              autoClose: 1000,
            });
            this.loadTicketDoccuments();
         
          })
          .catch((error) => {
            this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });

          })

          .finally(() => (this.loading = false));
    },

    editTicketDoccument(form) {
      const formData = new FormData();
        const keys = Object.keys(form);
        for (let n = 0; n < keys.length; n += 1) {
          if (form[keys[n]] !== null) {
            formData.append(keys[n], form[keys[n]]);
          }
        }
        // formData.append("staff_id", this.staff_id);
     
        this.loading = true;
        this.$axios
          .post(`staff/clientserviceticket/document/${form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            document.querySelector("#formFileNew").value = ''
            toast.success("Updated Succsessfully", {
              autoClose: 1000,
            });
            this.loadTicketDoccuments();
         
          })
          .catch((error) => {
            this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });

          })

          .finally(() => (this.loading = false));
    },
    ticketFinder(ticket){
      var attachment

      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment;
        // this.atchUrl = attachment;
      
      } 
      
      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment;
        // this.atchUrl = attachment;

      } 

var arr = {}

      console.log(attachment)
var pdf = attachment
 var  extensionAttachment = attachment.split(".").pop();
    
arr.attachment = attachment
arr.pdf = pdf
arr.extensionAttachment = extensionAttachment

  if (extensionAttachment == "pdf" || extensionAttachment == "PDF") {
    arr.attachment =  "/assets/image/pdf.png";
            }

return arr.attachment;



      // this.attachmentPdf = this.atchUrl;
      //   this.extensionAttachment = this.coiPreImg.split(".").pop();
    },
    removeEror() {
      this.projectId = ""
      this.edit = 'false'
      this.form = {} 
      this.form.errors = {}
      this.msgErrorProjectId = "";
      this.filterValue = "";
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadTicket();
    },
    showBtn(sts) {
      this.showBtns = sts;
      if (sts == "active") {
        this.staffTicketVal = "true";
      } else {
        this.staffTicketVal = "false";
      }
    },

    findClientServiceIdForFilter(e) {
      var code = e.target.value;
      console.log(code);

      var data = this.clients.find((o) => o.clientServiceNo == code);
      console.log(data);
      if (data) {
        this.filterValue = data.id;

        this.loadTicket();
      } else {
        this.$swal.fire({
          confirmButtonColor: "#00364f",
          icon: "error",
          title: "Oops...",
          text: "Please Enter Valid Project Code",
        });
      }
    },

    findClientServiceId() {
   
      var code =    this.projectId
      console.log(code);
      this.msgErrorProjectId = "";

      var data = this.clients.find((o) => o.clientServiceNo == code);
      console.log(data);
      if (data) {
        this.form.client_service_id = data.id;
      } else {
        this.msgErrorProjectId = "Invalid Project ID";
      }
    },
    closeTicket(data) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Close it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, Close it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .post(
                `staff/clientserviceticket/close/${data.id}`,
                {},
                {
                  headers: { Authorization: "Bearer " + localStorage.accessToken },
                }
              )
              .then((res) => {
                res;

                toast.success("Closed  Succesfully", {
                  autoClose: 1000,
                });
                this.loadTicket();
              })
              .finally(() => (this.loading = false))

              .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });
          }
        });
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },

    

    attachmentImageUpload(e) {
      console.log(e.target.files["0"]);
      this.msgImgError1 = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError1 = "Attachment Size Too Big 2MB";
        return false;
      } else {
        // var fileName;
        // fileName = document.querySelector("#file-input1").value;
        // this.extensionProfile = fileName.split(".").pop();
        // if (
        //   this.extensionProfile == "png" ||
        //   this.extensionProfile == "jpg" ||
        //   this.extensionProfile == "jpeg"
        // ) {
        //   this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
        this.form.attachment = e.target.files["0"];

        console.log(this.form.attachment)
        // } else {
        //   this.msgImgError1 = "File Type Must JPG , PNG, JPEG";
        // }
      }
    },
  
    createTicket() {
      const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }

      this.loading = true;
      this.$axios
        .post("staff/clientserviceticket", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log('=======POST===================');
          console.log(res.data.data);
          
          // this.clients = res.data.data.data;
          toast.success("Form Was Sent  Succesfully ", {
            autoClose: 1000,
          });
          this.form = {};
          $("#exampleModal").modal("hide");
          $("#atachmentModal").modal("show");
          this.loadTicket();
          this.attechmetsSandticketAssignValue(res.data.data)
        })
        .catch((error) => {
          this.form.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
    },
    ticketAssignValue(tiket) {
      this.removeEror()
      console.log(tiket)
this.ticket_id = tiket.id
if(tiket.client_service){
    this.projectId = tiket.client_service.clientServiceNo
}
      
      this.form = tiket;
      if (tiket.remark) {
        this.form.remark = tiket.remark;
      } else {
        this.form.remark = "";
      }

      this.edit = "true";

      // this.loadTicketDoccuments();
    },
    updateTicket() {
   
      const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }
      var ticketForDoc = this.form

      this.loading = true;
      this.$axios
        .post(`staff/clientserviceticket/${this.form.id}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          // this.clients = res.data.data.data;
          toast.success("Form Was Sent  Succesfully ", {
            autoClose: 1000,
          });
          this.form = {};
          this.edit = "false";
          $("#exampleModal").modal("hide");
          $("#atachmentModal").modal("show");
        
          this.loadTicket();
          this.attechmetsSandticketAssignValue(ticketForDoc)
        })

        .finally(() => (this.loading = false))
        .catch((error) => {
          this.form.errors = error.response.data;
        });
    },
    loadTicket(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
        
          if (this.filterType == "date") {
            pageUrl += `staff/clientserviceticket?staff=false&per_page=${this.per_page}&date=${this.filterValue}`;
          } else {
            pageUrl += `staff/clientserviceticket?staff=false&per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `staff/clientserviceticket?staff=false&per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.tickets = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = "";
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.viewAny = false
          }
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });

      var userData = JSON.parse(localStorage.getItem("userData"));

      this.$axios
        .get(
          `staff/clientserviceticket?relation_type=staff&relation_id=${userData.id}&per_page=${this.per_page}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data.data.data);
          this.ticketstaffs = res.data.data.data;

          this.pagination1 = res.data.data;
        });
    },
    loadTicketervices() {
      this.loading = true;
      this.$axios
        .get("staff/clientservice", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.clients = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadTicketDoccuments() {
      this.loading = true;
      this.$axios
        .get(`staff/clientserviceticket/document?ticket_id=${this.ticket_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.ticketDoccuments = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadDepartments() {
      this.loading = true;
      this.$axios
        .get(`staff/department?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.departMents = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.staffPageTitles.ticket
    console.log(this.$store.state.staffPageTitles.ticket)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadTicketervices();
      this.loadTicket();
      this.loadDepartments();
            
      this.loadPageTitle()
   
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}

.nav-tabs .nav-link.active {
  position: relative;
  color: white;
  background-color: #f21000;
  border-radius: 5px;
}

.nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(30deg, #f21000, rgb(240 103 103 / 50%)) !important;
  transition: transform 0.3s;
  transform: translate3d(0, 150%, 0);
}

input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
</style>
